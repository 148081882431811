<template>
  <div>
    <div class="edit_box  mrb20">
      <div class="size16">
        筛选查询
      </div>
      <div class="mrt20">
        <el-form ref="form" :model="selectForm" label-width="80px" class="flex">
          <el-form-item label="原著名称">
            <el-input v-model="selectForm.name" placeholder="请输入原著名称" style="width: 200px;" clearable></el-input>
          </el-form-item>
          <el-form-item label="原著类型">
            <el-select v-model="selectForm.type" placeholder="请选择原著类型" clearable>
              <el-option v-for="item in typeOption" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="selectForm.status" placeholder="请选择状态" clearable>
              <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getList(Object.assign(selectForm,pageInfo))">查找</el-button>
            <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="edit()">新增</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="mrb20 bg-white pd20">
      <div class="mrb20">数据列表</div>
      <div>
        <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" style="width: 100%">
          <el-table-column label="序号" prop="id" align="center">
          </el-table-column>
          <el-table-column prop="name" label="原著名称" width="120" align="center">
          </el-table-column>
          <el-table-column prop="cover" label="原著图片" width="120" align="center">
            <template slot-scope="scope">
              <el-image :preview-src-list="[scope.row.cover]" style="width: 100px; height: 100px" :src="scope.row.cover" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序" align="center">
          </el-table-column>
          <el-table-column prop="introduce" label="原著简介" align="center">
            <template slot-scope="scope">
              <el-tooltip popper-class="tip-class" class="item" effect="dark" :content="scope.row.introduce" placement="top">
                <div class="white-space"> {{ scope.row.introduce }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="原著类型" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.type == 0" type="primary" plain size="mini" disabled>动漫</el-button>
              <el-button v-if="scope.row.type == 1" type="primary" plain size="mini" disabled>影视</el-button>
              <el-button v-if="scope.row.type == 2" type="primary" plain size="mini" disabled>游戏</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="是否启用" width="100px">
            <template slot-scope="scope">
              <el-button type="info" plain size="mini" v-if="scope.row.status == 0">禁用</el-button>
              <el-button v-if="scope.row.status == 1" type="success" plain size="mini">启用</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" width="160" align="center">
          </el-table-column>
          <!-- <el-table-column prop="created_user_name" label="创建者" width="100">
          </el-table-column> -->
          <el-table-column prop="updated_at" label="更新时间" width="160" align="center">
          </el-table-column>
          <!-- <el-table-column prop="updated_user_name" label="操作者">
          </el-table-column> -->
          <el-table-column fixed="right" label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-edit" type="primary" @click="edit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="mrt20 flex justify-end ">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
          </el-pagination>
        </div>

      </div>
    </div>

    <!-- 新增 -->
    <el-dialog title="原著" :visible.sync="originalVisible" width="40%">
      <el-form ref="originaForm" :model="originaForm" label-width="100px">
        <el-form-item label="原著名称" required>
          <el-input v-model="originaForm.name"></el-input>
        </el-form-item>
        <el-form-item label="原著简介" required>
          <el-input type="textarea" v-model="originaForm.introduce" :autosize="{ minRows: 4, maxRows: 6}"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="originaForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="原著类型">
          <el-select v-model="originaForm.type" placeholder="请选择">
            <el-option v-for="item in  typeOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否推荐">
          <el-select v-model="originaForm.is_rec" placeholder="请选择">
            <el-option v-for="item in  isrecOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原著的图片">
          <file-pic :file='originaForm.cover' @input='originaForm.cover = $event'></file-pic>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="originaForm.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="originalVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">{{ dialogTitle }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import filePic from "@/components/common/filePic";
import { statusOption, statusDetail } from "@/utils/statusData";
export default {
  components: { filePic },
  props: {},
  data() {
    return {
      statusOption,
      statusDetail,
      originalVisible: false,
      pageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      selectForm: {
        name: "",
        type: "",
        status: "",
      },
      // 新增
      originaForm: {
        id: "",
        name: "",
        introduce: "",
        sort: "",
        type: "",
        is_rec: "",
        cover: "",
        status: "",
      },

      // statusOption: [
      //   {
      //     value: 1,
      //     label: "禁用",
      //   },
      //   {
      //     value: 2,
      //     label: "启用",
      //   },
      // ],

      typeOption: [
        {
          value: 0,
          label: "动漫",
        },
        {
          value: 1,
          label: "影视",
        },
        {
          value: 2,
          label: "游戏",
        },
      ],
      isrecOption: [
        {
          value: 1,
          label: "推荐",
        },
        {
          value: 0,
          label: "不推荐",
        },
      ],
      tableData: [],
      evaluatePictureList: "",
      ifMaterialEdit: 0, // 0表示编辑，1表示新增
      dialogTitle: "",
    };
  },

  created() {
    this.getList(this.pageInfo);
  },
  mounted() {},
  computed: {},

  methods: {
    // 新增编辑
    edit(row) {
      if (row) {
        // console.log("点击编辑", row);
        this.ifMaterialEdit = 0;
        this.originaForm.id = row.id;
        this.originalVisible = true;
        this.dialogTitle = "编辑";
        this.originaForm = { ...row };
      } else {
        // console.log("新增咯");
        this.ifMaterialEdit = 1;
        this.originalVisible = true;
        this.dialogTitle = "新增";
        this.originaForm = {
          id: "",
          name: "",
          introduce: "",
          sort: "",
          type: "",
          is_rec: "",
          cover: "",
          status: "",
        };
      }
    },
    async onSubmit() {
      // 新增接口对接
      if (this.ifMaterialEdit == 1) {
        // console.log("新增管理", this.originaForm);
        const data = await this.$Api.originalEdit(this.originaForm);
        if (data.code !== 200) {
          this.$message.error(data.msg);
          return;
        }
        this.$message.success("新增成功");
        this.originalVisible = false;
        this.getList(this.pageInfo);
      } else if (this.ifMaterialEdit == 0) {
        // console.log("编辑管理", this.editForm);
        // 编辑接口对接
        const data = await this.$Api.originalEdit(this.originaForm);
        this.$message.success("编辑成功");
        this.originalVisible = false;
        this.getList(this.pageInfo);
      }
    },
    async getList(pageInfo) {
      if (pageInfo.status == "") {
        this.selectForm.status = "-1";
      }
      const { data } = await this.$Api.originalList(pageInfo);
      this.tableData = data.list;
      console.log("原著管理", this.tableData);
      console.log("data.limit", data.limit);
      this.pageInfo.limit = data.limit;
      this.pageInfo.page = data.page;
      this.pageInfo.count = data.count;
      this.pageInfo.pageNum = data.pageNum;
      // Object.assign(this.pageInfo, data);
      // console.log(data, "成功的原著列表");
    },
    handleSizeChange(val) {
      // console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange(val) {
      // console.log("val", val);
      this.pageInfo.page = val;
      this.getList(this.pageInfo);
    },
    // 批量删除
    // batchdelete() {
    //   this.$confirm("确定要删除该商品信息吗?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       console.log("点击删除");
    //       return;
    //       this.$message({
    //         type: "success",
    //         message: "删除成功!",
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    // 单个删除
    handleDelete(row) {
      this.$confirm("确定要删除该商品信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.originalDel({ ids: [row] });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
  },
};
</script>


<style lang='scss' scoped>
.tip-class {
  max-width: 200px !important;
  background-color: red;
}
::v-deep .el-tooltip__popper.is-dark {
  width: 700px !important;
}
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
</style>